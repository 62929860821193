.headerSatta .heading {
  background-color: #fff;
  border-radius: 25px;
}

.heading h3 {
  font-weight: bold;
  color: red;
}

.monthYrTbl {
  text-transform: uppercase;
  text-wrap: nowrap;
}

.heading h6 {
  text-shadow: 1px 1px red;
}

.dayResult h5 {
  color: yellow;
  font-size: 20px;
}

.dayResult h6 {
  color: #fff;
  font-weight: bold;
}

.dayResult h2 {
  color: #2bfeb1;
  font-weight: bold;
  margin: 1rem 0rem;
}

a {
  text-decoration: unset !important;
}

/* ======dayResultComponent====== */

.dayResultCompo h1 {
  background-color: yellow;
  color: GREEN;
  font-size: 20px;
  font-weight: bold;
  padding: 0.5rem 0px;
}

.dayResultCompo .div-left {
  background-color: #17202a;
}

.div-left h3 {
  color: yellow;
}

.div-left span {
  color: #fff;
}

.dayResultCompo .div-right {
  background-color: #f5ecce;
}

.div-right h3:nth-child(2) {
  color: blue;
}

/* =====footer css===== */
#month-select,
#year-select,
#game-select,
#go-button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-weight: bold;
}

#go-button {
  background-color: rgb(24, 109, 237);
  color: white;
  cursor: pointer;
}

#go-button:hover {
  background-color: navy;
}

/* ======antd table====== */
.tableStyle thead tr th {
  text-wrap: nowrap;
  background-color: yellow !important;
  padding: 0.5rem !important;
  text-align: center;
}

.tableStyle table {
  border: 2px;
  border-radius: 8px !important;
  text-wrap: nowrap;
}

.sattaKing {
  text-align: center;
  background: #fff;
  border-radius: 10px;
  padding: 10px 10px;
  border: 1px solid brown;
}

.sattaKing h5 {
  color: #000 !important;
  font-weight: bold;
}

.ant-table-content tr {
  background-color: #ddd !important;
  padding: 0px !important;
}

.ant-table-content td,
.ant-table-content tr,
.ant-table-content th {
  padding: 5px 0px !important;
  text-align: center !important;
  border-right: 1px solid #ccc !important;
  vertical-align: middle !important;
}

.ant-table-content th {
  padding: 5px 10px !important;
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow 0.75s linear infinite;
  animation: spinner-grow 0.75s linear infinite;
}

/* ==========scrollbar======= */
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
  background-color: #837e7e;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #837e7e;
  border: 1px solid;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #c1c6c2;
}

/* blink text */

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.blinking-text {
  animation: blink 1s infinite;
}

/* =====text color animation====== */
h2 {
  font-size: 16px;
  font-weight: bold;
  /* font-family: serif; */
  color: transparent;
  /* text-align: center; */
  animation: effect 1s linear infinite;
  text-transform: uppercase;
}

@keyframes effect {
  0% {
    background: linear-gradient(to left, #de2828, #410404);
    -webkit-background-clip: text;
  }

  100% {
    background: linear-gradient(to left, #ed1818, #df1f1f);
    -webkit-background-clip: text;
  }
}

.bottom-section p {
  font-size: 10px !important;
  color: #000;
}

.bottom-section h4 {
  font-size: 15px;
  color: red !important;
}





/* advertisement section */
/* Main ad container with new background and color scheme */
.advertisementSection .ad-section {
  width: 92%;
  /* Covers 90%+ of screen width */
  margin: 20px auto;
  border: 3px solid #e67e22;
  /* Orange border */
  padding: 30px;
  border-radius: 12px;
  text-align: center;
  background-color: #fef9e7;
  /* Light cream background */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

/* Heading styles */
.advertisementSection h3 {
  color: #d35400;
  /* Darker orange */
  font-size: 30px;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.advertisementSection h4 {
  color: #2c3e50;
  /* Dark gray text */
  font-size: 22px;
  margin: 10px 0;
}

/* Button styles */
.advertisementSection .ad-btn {
  /* display: inline-block; */
  /* padding: 10px; */
  /* margin: 5px; */
  /* font-size: 18px; */
  color: #fff;
  background-color: #e74c3c;
  border: none;
  /* border-radius: 7px; */
  /* cursor: pointer; */
  /* transition: background-color 0.3s ease; */
  text-transform: uppercase;
  text-decoration: none;
}

/* Hover effect for buttons */
.advertisementSection .ad-btn:hover {
  background-color: #c0392b;
  color: #fff;
  /* Darker red on hover */
}

.fa-whatsapp {
  background: green;
  border-radius: 65%;
}

.fa-phone {
  color: green;
  /* border: solid 1px #fff; */
  box-shadow: 0px 0px 7px #fff;
  background: #fff;
  padding: 0.1rem;
  border-radius: 5px;
}